import { useState, useEffect } from 'react';
import Select from "react-select";
import { FullDetails, Game, SummarizedDetails, allStatisticsColumns } from "./mccTrackermodels";
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

interface HighLevelStatsContainerProps {
    games: Game[];
}

export function HighLevelStatsContainer({ games } : HighLevelStatsContainerProps) {
    
    const gridStyle = { minHeight: 300 };
    const [years, setYears] = useState<string[]>([]);
    const [divisions, setDivisions] = useState<string[]>([]);
    const [semesters] = useState<string[]>(["1", "2", "playoffs"]);

    const [selectedYears, setSelectedYears] = useState<string[]>([]);
    const [selectedSemesters, setSelectedSemesters] = useState<string[]>([]);
    const [selectedDivisions, setSelectedDivisions] = useState<string[]>([]);

    const handleSelectedYearChanged = (selectedOption: any) => {
        setSelectedYears(selectedOption.map((option: any) => option.value));
    }

    const handleSelectedSemesterChanged = (selectedOption: any) => {
        setSelectedSemesters(selectedOption.map((option: any) => option.value));
    }

    const handleSelectedDivisionChanged = (selectedOption: any) => {
        setSelectedDivisions(selectedOption.map((option: any) => option.value));
    }

    const getTeamStats = () => {
        var teamDetailsList: FullDetails[] = [];
        var eligibleGames = games.filter(g => (selectedYears.includes(g.year) || selectedYears.length === 0) && (selectedSemesters.includes(g.semester) || selectedSemesters.length === 0 )&& (selectedDivisions.includes(g.division) || selectedDivisions.length === 0));
        var teamsToCalculate = eligibleGames.map(g => g.red).concat(eligibleGames.map(g => g.yellow)).filter((v, i, a) => a.indexOf(v) === i);

        teamsToCalculate.forEach(t => {
            var teamGames = eligibleGames.filter(g => g.red === t || g.yellow === t);
            var teamWins = teamGames.filter(g => (g.redTeamWon && g.red === t) || (g.yellowTeamWon && g.yellow === t)).length;
            var teamLosses = teamGames.filter(g => (g.redTeamWon && g.yellow === t) || (g.yellowTeamWon && g.red === t)).length;
            var teamTies = teamGames.filter(g => g.tie).length;

            var teamDetails = {
                id: t,
                name: t,
                team: t,
                wins: teamWins,
                losses: teamLosses,
                ties: teamTies,
                ptsFor: [],
                ptsAgainst: [],
                hammerSuccesses: 0,
                hammerFailures: 0,
                forces: 0,
                forceFailures: 0,
                steals: 0,
                stealFailures: 0,
                concededSteals: 0,
                concededStealFailures: 0,
            } as FullDetails;

            teamGames.forEach(g => {
                if (g.red === t) {
                    calculateEndStatsForRed(teamDetails, g);
                } else {
                    calculateEndStatsForYellow(teamDetails, g);
                }
            });
            teamDetailsList.push(teamDetails);

        });
        return convertFullDetailsToSummarizedDetails(teamDetailsList);

    }

    const calculateEndStatsForRed = (teamDetails: FullDetails, game: Game) => {
        var pointsInGame = game.scoreboard.reduce((a, b) => a + b.redScore, 0);
        var pointsAgainstInGame = game.scoreboard.reduce((a, b) => a + b.yellowScore, 0);
        var hammerConversions = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && e.redScore >= 2).length;
        var hammerFailures = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && (e.redScore === 1 || e.yellowScore >= 1)).length;

        var forces = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.yellowScore === 1).length;
        var forceFailures = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.yellowScore > 1).length;

        var steals = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.redScore >= 1).length;
        var stealFailures = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.redScore === 0).length;

        var concededSteals = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && e.yellowScore >= 1).length;
        var concededStealFailures = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && e.yellowScore === 0).length;


        teamDetails.ptsFor.push(pointsInGame);
        teamDetails.ptsAgainst.push(pointsAgainstInGame);
        teamDetails.hammerSuccesses += hammerConversions;
        teamDetails.hammerFailures += hammerFailures;
        teamDetails.forces += forces;
        teamDetails.forceFailures += forceFailures;
        teamDetails.steals += steals;
        teamDetails.stealFailures += stealFailures;
        teamDetails.concededSteals += concededSteals;
        teamDetails.concededStealFailures += concededStealFailures;
    }

    const calculateEndStatsForYellow = (teamDetails: FullDetails, game: Game) => {
        var pointsInGame = game.scoreboard.reduce((a, b) => a + b.yellowScore, 0);
        var pointsAgainstInGame = game.scoreboard.reduce((a, b) => a + b.redScore, 0);
        var hammerConversions = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && e.yellowScore >= 2).length;
        var hammerFailures = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && (e.yellowScore === 1 || e.redScore >= 1)).length;

        var forces = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.redScore === 1).length;
        var forceFailures = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.redScore > 1).length;

        var steals = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.yellowScore >= 1).length;
        var stealFailures = game.scoreboard.filter(e => e.hammerTeamName !== teamDetails.team && e.yellowScore === 0).length;

        var concededSteals = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && e.redScore >= 1).length;
        var concededStealFailures = game.scoreboard.filter(e => e.hammerTeamName === teamDetails.team && e.redScore === 0).length;

        teamDetails.ptsFor.push(pointsInGame);
        teamDetails.ptsAgainst.push(pointsAgainstInGame);
        teamDetails.hammerSuccesses += hammerConversions;
        teamDetails.hammerFailures += hammerFailures;
        teamDetails.forces += forces;
        teamDetails.forceFailures += forceFailures;
        teamDetails.steals += steals;
        teamDetails.stealFailures += stealFailures;
        teamDetails.concededSteals += concededSteals;
        teamDetails.concededStealFailures += concededStealFailures;
    }

    const displayableNumber = (num: number, addPercentSign: boolean):string => {
        return Number.parseFloat(num.toFixed(2)).toString() + (addPercentSign ? '%' : '');
    }

    const convertFullDetailsToSummarizedDetails = (fullDetails: FullDetails[]): SummarizedDetails[] => {
        return fullDetails.map(fd => {
            return {
                id: fd.id,
                name: fd.name,
                team: fd.team,
                wins: fd.wins,
                losses: fd.losses,
                ties: fd.ties,
                averagePts: displayableNumber(fd.ptsFor.reduce((a, b) => a + b, 0) / fd.ptsFor.length, false),
                averagePtsAgainst: displayableNumber(fd.ptsAgainst.reduce((a, b) => a + b, 0) / fd.ptsAgainst.length, false),
                hammerEfficiency: displayableNumber((100* fd.hammerSuccesses / (fd.hammerSuccesses + fd.hammerFailures)), true),
                forceEfficiency: displayableNumber(100* fd.forces / (fd.forces + fd.forceFailures), true),
                stealEfficiency: displayableNumber(100* fd.steals / (fd.steals + fd.stealFailures), true),
                stealDefenseEfficiency: displayableNumber(100* fd.concededSteals / (fd.concededSteals + fd.concededStealFailures), true),
                hammerMinusSteal: displayableNumber((fd.hammerSuccesses / (fd.hammerSuccesses + fd.hammerFailures)) - (fd.concededSteals / (fd.concededSteals + fd.concededStealFailures)), true)
            } as SummarizedDetails;
        });
    }

    useEffect(() => {
        const years = games.map((g: any) => g.year).filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i);
        setYears(years);
        setSelectedYears(years);
        const divisions = games.map((g: any) => g.division).filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i);
        setDivisions(divisions);
        setSelectedDivisions(divisions);
        setSelectedSemesters(semesters);
    }, [games]);

    if(!games || games.length === 0){
        return (
            <></>
        );
    }

    return (
        <div>
            <h3>All Teams</h3>
            <div style={{display: 'flex'}}>
                <label style={{padding:'10px'}}>
                    Years:
                    <Select isMulti options={years.map((year) => ({ value: year, label: year }))} onChange={handleSelectedYearChanged} value={selectedYears.map((year) => ({ value: year, label: year}))} />
                </label>

                <label style={{padding:'10px'}}>
                    Semesters:
                    <Select isMulti options={semesters.map((semester) => ({ value: semester, label: semester }))} onChange={handleSelectedSemesterChanged} value={selectedSemesters.map((semester) => ({ value: semester, label: semester }))} />
                </label>

                <label style={{padding:'10px'}}>
                    Divisions:
                    <Select isMulti options={divisions.sort().map((division) => ({ value: division, label: division }))} onChange={handleSelectedDivisionChanged} value={selectedDivisions.map((division) => ({ value: division, label: division }))} />
                </label>

            </div>
            <div>
                <ReactDataGrid idProperty='id' columns={allStatisticsColumns} dataSource={getTeamStats()} style={gridStyle} />
            </div>
        </div>
    );
};