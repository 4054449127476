import { useEffect, useState } from "react";
import { DivisionName, Game, GameTime, League, Skips, Scoreboard } from "./mccTrackermodels"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { ScoreboardContainer } from "./ScoreboardContainer";
import { Button } from "@mui/material";

//create container
export function AddGameContainer() {

   const alphaSkips = [
        Skips.Packard,
        Skips.Apel,
        Skips.Blizzard,
        Skips.Geiger,
        Skips.Kraus,
        Skips.McLellan,
        Skips.Delsman,
        Skips.Binish,
        Skips.Stolte,
        Skips.Severson,
        Skips.Kania
   ];
    
   const bravoSkips = [
        Skips.Mader,
        Skips.Stolte,
        Skips.Kropidlowski,
        Skips.Eernisse,
        Skips.Severson,
        Skips.Lookatch,
        Skips.Moorhead,
        Skips.Kania,
        Skips.Conrardy,
        Skips.Deuhs,
        Skips.Lindsey,
        Skips.MacGillis,
   ];

   const wednesdaySkips = [
        Skips.Packard,
        Skips.McLellan,
        Skips.Coleman,
        Skips.Mueller,
        Skips.Apel,
        Skips.Stolte,
        Skips.Slane,
        Skips.Nichols,
        Skips.Severson,
        Skips.Geiger,
        Skips.Knetzger,
        Skips.Rasche,
        Skips.Czachor,
        Skips.Yogerst,
        Skips.Binish,
        Skips.Rickaby,
        Skips.Moorhead,
        Skips.Edgren,
        Skips.Delsman,
        Skips.Lookatch
   ];


   const defaultYear = "2024-2025";
   const defaultSemester = "fall";
   //const semester = "spring";
   //const semester = "playoffs";=
    const [year, setYear] = useState<string>(defaultYear);
    const [semester, setSemester] = useState<string>(defaultSemester);
    const [league, setLeague] = useState<League>(League.Carson);
    const [division, setDivision] = useState<DivisionName>();
    const [yellow, setYellow] = useState<string>("");
    const [red, setRed] = useState<string>("");
    const [hammerTeamName, setHammerTeamName] = useState<string>("");
    const [yellowScore, setYellowScore] = useState<number>(0);
    const [redScore, setRedScore] = useState<number>(0);
    const [skips, setSkips] = useState<Skips[]>([]);
    const [game, setGame] = useState<Game>({ yellow: "", red: "", scoreboard: [], yellowTeamWon: false, redTeamWon: false, tie: false, gameTime: "", sheet: 1, division: "", semester: semester, league: League.Carson, year: year } as Game);

   const [saveSuccessful, setSaveSuccessful] = useState<boolean>(false);

   const handleLeagueChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        const newLeague = event.target.value as League;
        setLeague(newLeague);
        if(newLeague === League.Carson){
            if(division === DivisionName.Alphas){
                setSkips(alphaSkips);
            }
            else{
                setSkips(bravoSkips);
            }
        }else{
            setSkips(wednesdaySkips);
        }
        setGame({...game, league: newLeague});
   }

   const handleDivisionChange = (newDivision: DivisionName) => {
        if(newDivision === game.division)
            return;

        setDivision(newDivision);
        if(newDivision === DivisionName.Alphas){
            setSkips(alphaSkips);
        }else{
            setSkips(bravoSkips);
        }
        setRed("");
        setYellow("");
        setHammerTeamName("");
        setGame({...game, division: newDivision, red: "", yellow: ""});

   }

   const handleGameTimeChange = (gameTime: GameTime) => {
        setGame({...game, gameTime: gameTime});
   }

    const handleSheetChange = (sheet: number) => {
          setGame({...game, sheet: sheet});
    }

   const handleYellowSkipChange = (skip: Skips) => {
        setYellow(skip);
        setGame({...game, yellow: skip});
   }

   const handleRedSkipChange = (skip: Skips) => {
        setRed(skip);
        setGame({...game, red: skip});
   }    

   const handleHammerChange = (teamName: string) => {
        setHammerTeamName(teamName);
   }

   const handleYellowScoreUp = (event: React.MouseEvent<SVGSVGElement>) => {
        if(redScore > 0){
            setRedScore(redScore - 1);
        }else{
            setYellowScore(yellowScore + 1);
        }
   }

   const handleRedScoreUp = (event: React.MouseEvent<SVGSVGElement>) => {
        if(yellowScore > 0){
            setYellowScore(yellowScore - 1);
        }else{
            setRedScore(redScore + 1);
        }
   }

   const handleAddEnd = (event: React.MouseEvent<HTMLButtonElement>) => {
        //add end to game
        if(hammerTeamName !== yellow || hammerTeamName !== red){

        }

        const end: Scoreboard = { yellowScore: yellowScore, redScore: redScore, hammerTeamName: hammerTeamName, endNumber: game.scoreboard.length + 1 };

        //add end to game and save game
        const updatedGame = { ...game, scoreboard: [...game.scoreboard, end] };
        setGame(updatedGame);


        //reset scores and set hammer
        if(yellowScore > redScore){
            setHammerTeamName(red);
        }else if(redScore > yellowScore){
            setHammerTeamName(yellow);
        }

        setYellowScore(0);
        setRedScore(0);
        
   }

   const addEndButtonDisabled = () => {
        return yellow === "" || red === "" || yellow === red || hammerTeamName === "";
   }

   const saveGame =() => {
    var redFinalScore = game.scoreboard.map(end => end.redScore).reduce((a, b) => a + b);
    var yellowFinalScore = game.scoreboard.map(end => end.yellowScore).reduce((a, b) => a + b);
    game.redTeamWon = redFinalScore > yellowFinalScore;
    game.yellowTeamWon = yellowFinalScore > redFinalScore;
    game.tie = redFinalScore === yellowFinalScore;






        //Make a web api call to get all season data
        fetch('https://67m7uq3175.execute-api.us-east-1.amazonaws.com/Prod/api/Stats', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(game)
        })
        .then(data => {
            setSaveSuccessful(true);
        })
        .catch((error) => {
            console.error('Error:', error);
        });




   }
   if(saveSuccessful) {
        return (
            <div>
                <h1>Game Saved Successfully</h1>
            </div>
        );
   }

    return (
        <div>
            <h3>League:</h3>
            {league === League.Carson &&
                <>
                    <h3>Division:</h3>
                    <div>
                        <Button style={{ marginRight: "10px" }} variant={division === DivisionName.Alphas ? "contained" : "outlined"}  onClick={() => handleDivisionChange(DivisionName.Alphas)}>Alphas</Button>
                        
                        
                        <Button variant={division === DivisionName.Bravos ? "contained" : "outlined"}  onClick={() => handleDivisionChange(DivisionName.Bravos)}>Bravos</Button>
                    </div>
                </>
                }

            <h3>Game Time:</h3>
            <div>
                <Button style={{ marginRight: "10px" }} variant={game.gameTime === GameTime.Early ? "contained" : "outlined"} onClick={() => handleGameTimeChange(GameTime.Early)}>Early</Button>
                <Button style={{ marginRight: "10px" }} variant={game.gameTime === GameTime.Late ? "contained" : "outlined"} onClick={() => handleGameTimeChange(GameTime.Late)}>Late</Button>
                <Button variant={game.gameTime === GameTime.Other ? "contained" : "outlined"} onClick={() => handleGameTimeChange(GameTime.Other)}>Other</Button>
            </div>

            <h3>Sheet:</h3>
            <div>
                <Button style={{ marginRight: "10px" }} variant={game.sheet === 1 ? "contained" : "outlined"} onClick={() => handleSheetChange(1)}>1</Button>
                <Button style={{ marginRight: "10px" }} variant={game.sheet === 2 ? "contained" : "outlined"} onClick={() => handleSheetChange(2)}>2</Button>
                <Button style={{ marginRight: "10px" }} variant={game.sheet === 3 ? "contained" : "outlined"} onClick={() => handleSheetChange(3)}>3</Button>
                <Button style={{ marginRight: "10px" }} variant={game.sheet === 4 ? "contained" : "outlined"} onClick={() => handleSheetChange(4)}>4</Button>
                <Button variant={game.sheet === 5 ? "contained" : "outlined"} onClick={() => handleSheetChange(5)}>5</Button>
            </div>

            <h3>Red Team:</h3>
            <div>
                {skips.sort().map(s => (
                    <Button style={{ marginRight: "10px" }} variant={game.red === s ? "contained" : "outlined"} onClick={() => handleRedSkipChange(s)}>{s}</Button>
                ))}
            </div>

            <h3>Yellow Team:</h3>
            <div>
                {skips.sort().map(s => (
                    <Button style={{ marginRight: "10px" }} variant={game.yellow === s ? "contained" : "outlined"} onClick={() => handleYellowSkipChange(s)}>{s}</Button>
                ))}
            </div>

            

            <ScoreboardContainer game={game} />


            <h3>Hammer</h3>
            <div>
                <Button style={{ marginRight: "10px" }} variant={(hammerTeamName === yellow && hammerTeamName !== '')? "contained" : "outlined"} onClick={() => handleHammerChange(game.yellow)}>Yellow</Button>
                <Button variant={(hammerTeamName === red  && hammerTeamName !== '')? "contained" : "outlined"} onClick={() => handleHammerChange(game.red)}>Red</Button>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginRight: "10px" }}>
                    <FontAwesomeIcon icon={faAngleUp} style={{ color: "#db0606" }} className="fa-4x" onClick={handleRedScoreUp} />
                    {redScore}
                </div>
                <div>
                    <FontAwesomeIcon icon={faAngleDown} style={{ color: "#edf104" }} className="fa-4x" onClick={handleYellowScoreUp} />
                    {yellowScore}
                </div>
            </div>


            
            <Button variant="contained" disabled={addEndButtonDisabled()} onClick={handleAddEnd}>Add End</Button>
            <br/>
            <div style={{ padding: "20px" }}>
                {/* Your content goes here */}
            </div>
            <Button variant="contained" color="primary" onClick={saveGame}>Save Game</Button>
        </div>
    )
}