import { Routes, Route } from 'react-router-dom';
import Home from './components/Home';
import MccObsCalendar from './components/MccObsCalendar';
import Privacy from './components/Privacy';
import { AddGameContainer } from './components/mccTracker/AddGameContainer';
import { CurlingStatisticsContainer } from './components/mccTracker/CurlingStatisticsContainer';
import { LeagueStandings } from './components/LeagueStandings';
const Main = () => {
return (         
    <Routes>
    <Route path='/' element={<Home/>} />
    <Route path='/privacy' element={<Privacy/>} />
    <Route path='/addGame' element={<AddGameContainer/>} />
    <Route path='/mccObsCalendar' element={<MccObsCalendar/>} />
    <Route path='/curling' element={<CurlingStatisticsContainer/>} />
    <Route path='/leagueStandings' element={<LeagueStandings/>} />
  </Routes>
);
}
export default Main;