import { useEffect, useState } from "react";
import { Game } from "./mccTrackermodels";
import { SheetStatisticsContainer } from "./SheetStatisticsContainer";
import { TeamStatisticsContainer } from "./TeamStatisticsContainer";
import { HighLevelStatsContainer } from "./HighLevelStatsContainer";

//create container
export function CurlingStatisticsContainer() {

    const [games, setGames] = useState<Game[]>([]);
  

    useEffect(() => {
        fetch('https://67m7uq3175.execute-api.us-east-1.amazonaws.com/Prod/api/Stats')
            .then(response => response.json())
            .then(data => {
                setGames(data);
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <div>
            <HighLevelStatsContainer games={games} />

            <SheetStatisticsContainer games={games} />

            <TeamStatisticsContainer games={games} />
        </div>
    )
}