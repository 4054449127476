//create season model


//create league enum
export enum League {
    Carson = "Carson",
    Wednesday = "Wednesday",
}




export interface Game {
    yellow: string;
    red: string;
    scoreboard: Scoreboard[];
    yellowTeamWon: boolean;
    redTeamWon: boolean;
    tie: boolean;
    gameTime: string;
    sheet: number;
    division: string;
    semester: string;
    league: League;
    year: string;
}

export interface Scoreboard {
    endNumber: number;
    yellowScore: number;
    redScore: number;
    hammerTeamName: string;
}

export enum DivisionName {
    Alphas = "Alphas",
    Bravos = "Bravos"
}

export enum GameTime {
    Early = "Early",
    Late = "Late",
    Other = "Other"
}

export enum Skips {
    Kania = "Kania",
    Lindsey = "Lindsey",
    Blizzard = "Blizzard",
    McLellan = "McLellan",
    Packard = "Packard",
    Kraus = "Kraus",
    Lookatch = "Lookatch",
    Geiger = "Geiger",
    Apel = "Apel",
    Strzelczyk = "Strzelczyk",
    MacGillis = "MacGillis",
    Stolte = "Stolte",
    Moorhead = "Moorhead",
    Mader = "Mader",
    Delsman = "Delsman",
    Binish = "Binish",
    Deuhs = "Deuhs",
    Kropidlowski = "Kropidlowski",
    Severson = "Severson",
    Eernisse = "Eernisse",
    Davis = "Davis",
    Conrardy = "Conrardy",
    Slane = "Slane",
    Nichols = "Nichols",
    Knetzger = "Knetzger",
    Rasche = "Rasche",
    Czachor = "Czachor",
    Yogerst = "Yogerst",
    Rickaby = "Rickaby",
    Edgren = "Edgren",
    Coleman = "Coleman",
    Mueller = "Mueller",
    Boggio = "Boggio",
    Goelzer = "Goelzer"
}

export interface FullDetails {
    id: string;
    name: string;
    team: Skips;
    wins: number;
    losses: number;
    ties: number;
    ptsFor: number[];
    ptsAgainst: number[];
    hammerSuccesses: number;
    hammerFailures: number;
    forces: number;
    forceFailures: number;
    steals: number;
    stealFailures: number;
    concededSteals: number;
    concededStealFailures: number;
}

export interface SummarizedDetails {
    id: string;
    name: string;
    team: Skips;
    wins: number;
    losses: number;
    ties: number;
    averagePts: string;
    averagePtsAgainst: string;
    hammerEfficiency: string;
    forceEfficiency: string;
    stealEfficiency: string;
    stealDefenseEfficiency: string;
    hammerMinusSteal: string;
}

export interface SheetStats {
    sheet: number;
    yellowWins: number;
    redWins: number;
    ties: number;
}

export const allStatisticsColumns = [
    { name: 'name', header: 'Division', sortable: true, minWidth: 100, defaultFlex: 1 },
    { name: 'wins', header: 'Wins', sortable: true, minWidth: 100, defaultFlex: 1 },
    { name: 'losses', header: 'Losses', sortable: true, minWidth: 100, defaultFlex: 1 },
    { name: 'ties', header: 'Ties', sortable: true, minWidth: 100, defaultFlex: 1 },
    { name: 'averagePts', header: 'Average Pts', sortable: true, minWidth: 100, defaultFlex: 1 },
    { name: 'averagePtsAgainst', header: 'Average Pts Against', sortable: true, minWidth: 100, defaultFlex: 1 },
    { name: 'hammerEfficiency', header: 'Hammer Efficiency', sortable: true, minWidth: 100, defaultFlex: 1 },
    { name: 'forceEfficiency', header: 'Force Efficiency', sortable: true, minWidth: 100, defaultFlex: 1 },
    { name: 'stealEfficiency', header: 'Steal Efficiency', sortable: true, minWidth: 100, defaultFlex: 1 },
    { name: 'stealDefenseEfficiency', header: 'Opponent Steal Efficiency', sortable: true, minWidth: 100, defaultFlex: 1 }
];