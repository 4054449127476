import { Game } from "./mccTrackermodels"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHammer } from "@fortawesome/free-solid-svg-icons";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

export interface IScoreboardContainerProps {
    game: Game;
}

//create container
export function ScoreboardContainer({game}: IScoreboardContainerProps) {

    if(game.scoreboard.length === 0){
        return (
            <></>
        )
    }
    const redScore = game.scoreboard.map(end => end.redScore).reduce((a, b) => a + b);
    const yellowScore = game.scoreboard.map(end => end.yellowScore).reduce((a, b) => a + b);

    return (

        <TableContainer>
            <Table style={{borderCollapse: 'collapse'}}>
                <TableHead>
                    <TableRow style={{backgroundColor: 'lightcoral'}}>
                        <TableCell>{game.red}</TableCell>
                        {game.scoreboard.map((end, index) => (
                            <TableCell key={index}>{end.redScore} {end.hammerTeamName === game.red && <FontAwesomeIcon icon={faHammer} />}</TableCell>
                        ))}
                        <TableCell>{redScore}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell />
                        {game.scoreboard.map((end, index) => (
                            <TableCell key={index}>{index + 1}</TableCell>
                        ))}
                        <TableCell>Total</TableCell>
                    </TableRow>
                    <TableRow style={{backgroundColor: 'yellow'}}>
                        <TableCell>{game.yellow}</TableCell>
                        {game.scoreboard.map((end, index) => (
                            <TableCell key={index}>{end.yellowScore} {end.hammerTeamName === game.yellow && <FontAwesomeIcon icon={faHammer} />}</TableCell>
                        ))}
                        <TableCell>{yellowScore}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
    
}
/* 
<table>
            <tr style={{backgroundColor: 'red'}}>
                <td>{game.red}ddddddddddd</td>
                {game.scoreboard.map((end, index) => {
                    return <td key={index}>{end.redScore} {end.hammerTeamName === game.red && <FontAwesomeIcon icon={faHammer} />}</td>
                }
                )}
            </tr>
            <tr>
                <td/>
                {game.scoreboard.map((end, index) => {
                    return <td key={index}>{index + 1}</td>
                }
                )}
            </tr>
            <tr>
                <td style={{backgroundColor: 'yellow'}}>{game.yellow}</td>
                {game.scoreboard.map((end, index) => {
                    return <td key={index}>{end.yellowScore} {end.hammerTeamName === game.yellow && <FontAwesomeIcon icon={faHammer} />}</td>
                }
                )}
            </tr>
        </table> */