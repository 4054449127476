import { useEffect, useState } from "react";
import { Game, SheetStats } from "./mccTrackermodels"
import  Select  from "react-select";

import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

//create container
export interface ISheetStatisticsContainerProps {
    games: Game[];
}


export function SheetStatisticsContainer({games}: ISheetStatisticsContainerProps) {
    const gridStyle = { minHeight: 250 };
    const defaultSortInfo = { name: 'name', dir: 1 }
    const [years, setYears] = useState<string[]>([]);
    const [divisions, setDivisions] = useState<string[]>([]);
    const [semesters] = useState<string[]>(["1", "2", "playoffs"]);

    
    const [selectedYears, setSelectedYears] = useState<string[]>([]);
    const [selectedSemesters, setSelectedSemesters] = useState<string[]>([]);
    const [selectedDivisions, setSelectedDivisions] = useState<string[]>([]);


    const handleSelectedYearChanged = (selectedOption: any) => {
        setSelectedYears(selectedOption.map((option: any) => option.value));
    }

    const handleSelectedSemesterChanged = (selectedOption: any) => {
        setSelectedSemesters(selectedOption.map((option: any) => option.value));
    }

    const handleSelectedDivisionChanged = (selectedOption: any) => {
        setSelectedDivisions(selectedOption.map((option: any) => option.value));
    }

    const getStats = () => {
        var elligibleGames = games.filter((game: any) => {
            return (selectedYears.length === 0 || selectedYears.includes(game.year)) &&
            (selectedSemesters.length === 0 || selectedSemesters.includes(game.semester)) &&
            (selectedDivisions.length === 0 || selectedDivisions.includes(game.division));
        });

        var sheetStatsList: SheetStats[] = [];
        elligibleGames.forEach((game: any) => {
            var sheetStat = sheetStatsList.find(s => s.sheet === game.sheet);
            if(sheetStat === undefined){
                sheetStat = { sheet: game.sheet, yellowWins: 0, redWins: 0, ties: 0 };
                sheetStatsList.push(sheetStat);
            }
            if(game.yellowTeamWon){
                sheetStat.yellowWins++;
            }
            else if(game.redTeamWon){
                sheetStat.redWins++;
            }
            else{
                sheetStat.ties++;
            }

        });
        return sheetStatsList;
    }

    const columns = [
        { name: 'sheet', header: 'Sheet', minWidth: 100, defaultFlex: 1, sortable: true },
        { name: 'yellowWins', header: 'Yellow Wins', minWidth: 100, defaultFlex: 1, sortable: true },
        { name: 'redWins', header: 'Red Wins', minWidth: 100, defaultFlex: 1, sortable: true },
        { name: 'ties', header: 'Ties', minWidth: 100, defaultFlex: 1, sortable: true }
    ];

    useEffect(() => {
        const years = games.map((g: any) => g.year).filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i);
        setYears(years);
        setSelectedYears(years);
        const divisions = games.map((g: any) => g.division).filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i);
        setDivisions(divisions);
        setSelectedDivisions(divisions);
        setSelectedSemesters(semesters);
    }, [games]);

    return (
    <div>
        
        <h3>Stats by Sheet</h3>
        <div style={{display: 'flex'}}>
                <label style={{padding:'10px'}}>
                    Years:
                    <Select isMulti options={years.map((year) => ({ value: year, label: year }))} onChange={handleSelectedYearChanged} value={selectedYears.map((year) => ({value: year, label: year}))}/>
                </label>

                <label style={{padding:'10px'}}>
                    Semesters:
                    <Select isMulti options={semesters.map((semester) => ({ value: semester, label: semester }))} onChange={handleSelectedSemesterChanged} value={selectedSemesters.map((semester) => ({ value: semester, label: semester }))} />
                </label>

                <label style={{padding:'10px'}}>
                    Divisions:
                    <Select isMulti options={divisions.sort().map((division) => ({ value: division, label: division }))} onChange={handleSelectedDivisionChanged} value={selectedDivisions.map((division) => ({ value: division, label: division }))} />
                </label>

            </div>

        <ReactDataGrid idProperty="id" style={gridStyle} columns={columns} dataSource={getStats()} defaultSortInfo={{ name: 'sheet', dir: 1}} />
    </div>                    
    )
}