import { useState, useEffect } from 'react';
import Select from "react-select";
import { Game, Skips } from "./mccTrackermodels";
import { GameHistoryContainer } from './GameHistoryContainer';
import { RecordVsOpponentContainer } from './RecordVsOpponentContainer';
import { TeamGameTimeStatsContainer } from './TeamGameTimeStatsContainer';
import { TeamDivisionStatsContainer } from './TeamDivisionStatsContainer';
import { TeamSemesterStatsContainer } from './TeamSemesterStatsContainer';
import { TeamSeasonStatsContainer } from './TeamSeasonStatsContainer';

interface TeamStatisticsContainerProps {
    games: Game[];
}

export function TeamStatisticsContainer({ games } : TeamStatisticsContainerProps) {
    
    const [teams, setTeams] = useState<Skips[]>([]);

    
    const [selectedTeam, setSelectedTeam] = useState<string>("");


 
    const handleSelectedTeamChanged = (selectedOption: any) => {
        setSelectedTeam(selectedOption.value);
    }

    useEffect(() => {
        setTeams(games.map((g: any) => g.red).concat(games.map((g: any) => g.yellow)).filter((v: any, i: any, a: string | any[]) => a.indexOf(v) === i));
    }, [games]);

    return (
        <div>
            <div>
                <h2>Team Specific Stats</h2>
                <div  style={{display: 'flex'}}>
                    <label  style={{padding:'10px'}}>
                        Select team:
                        <Select options={teams.sort().map((team: string) => ({ value: team, label: team }))}
                                onChange={handleSelectedTeamChanged}/>
                    </label>
                </div>
 
                
                {selectedTeam && (
                    <>
                        <TeamSeasonStatsContainer games={games} team={selectedTeam}/>
                        <TeamSemesterStatsContainer games={games} team={selectedTeam}/>
                        <TeamDivisionStatsContainer games={games} team={selectedTeam}/>
                        <TeamGameTimeStatsContainer games={games} team={selectedTeam}/>
                        <RecordVsOpponentContainer games={games} team={selectedTeam} />
                        <GameHistoryContainer games={games} team={selectedTeam}/>
                    </>
                )}
                
            </div>                   
        </div>                    
    )
}