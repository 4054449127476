
import './Home.css';

const Home = () => {

    return <div className="App">
      <header className="App-header">
        <h2>Home of all apps created by Austin Binish</h2>
        </header>
        </div>;
}
export default Home;