import { FullDetails, Game, SummarizedDetails, allStatisticsColumns } from "./mccTrackermodels"
import ReactDataGrid from '@inovua/reactdatagrid-community';
import '@inovua/reactdatagrid-community/index.css';

//create container
export interface ITeamSeasonStatsContainerProps {
    games: Game[];
    team: string;
}

export function TeamSeasonStatsContainer({games, team}: ITeamSeasonStatsContainerProps) {
    const gridStyle = { minHeight: 300 };

    const getTeamStats = () => {
        var teamDetailsList: FullDetails[] = [];
        var eligibleGames = games.filter(g => g.yellow === team || g.red === team);
        var seasonsToCalculate = eligibleGames.map(g => g.year).filter((v, i, a) => a.indexOf(v) === i);

        seasonsToCalculate.forEach(t => {
            var seasonGames = eligibleGames.filter(g => g.year === t);
            var teamWins = seasonGames.filter(g => (g.redTeamWon && g.red === team) || (g.yellowTeamWon && g.yellow === team)).length;
            var teamLosses = seasonGames.filter(g => (g.redTeamWon && g.yellow === team) || (g.yellowTeamWon && g.red === team)).length;
            var teamTies = seasonGames.filter(g => g.tie).length;

            var teamDetails = {
                id: t,
                name: t,
                team: t,
                wins: teamWins,
                losses: teamLosses,
                ties: teamTies,
                ptsFor: [],
                ptsAgainst: [],
                hammerSuccesses: 0,
                hammerFailures: 0,
                forces: 0,
                forceFailures: 0,
                steals: 0,
                stealFailures: 0,
                concededSteals: 0,
                concededStealFailures: 0,
            } as FullDetails;

            seasonGames.forEach(g => {
                if (g.red === team) {
                    calculateEndStatsForRed(teamDetails, g);
                } else {
                    calculateEndStatsForYellow(teamDetails, g);
                }
            });
            teamDetailsList.push(teamDetails);

        });
        return convertFullDetailsToSummarizedDetails(teamDetailsList);

    }

    const calculateEndStatsForRed = (teamDetails: FullDetails, game: Game) => {
        var pointsInGame = game.scoreboard.reduce((a, b) => a + b.redScore, 0);
        var pointsAgainstInGame = game.scoreboard.reduce((a, b) => a + b.yellowScore, 0);
        var hammerConversions = game.scoreboard.filter(e => e.hammerTeamName === team && e.redScore >= 2).length;
        var hammerFailures = game.scoreboard.filter(e => e.hammerTeamName === team && (e.redScore === 1 || e.yellowScore >= 1)).length;

        var forces = game.scoreboard.filter(e => e.hammerTeamName !== team && e.yellowScore === 1).length;
        var forceFailures = game.scoreboard.filter(e => e.hammerTeamName !== team && e.yellowScore > 1).length;

        var steals = game.scoreboard.filter(e => e.hammerTeamName !== team && e.redScore >= 1).length;
        var stealFailures = game.scoreboard.filter(e => e.hammerTeamName !== team && e.redScore === 0).length;

        var concededSteals = game.scoreboard.filter(e => e.hammerTeamName === team && e.yellowScore >= 1).length;
        var concededStealFailures = game.scoreboard.filter(e => e.hammerTeamName === team && e.yellowScore === 0).length;


        teamDetails.ptsFor.push(pointsInGame);
        teamDetails.ptsAgainst.push(pointsAgainstInGame);
        teamDetails.hammerSuccesses += hammerConversions;
        teamDetails.hammerFailures += hammerFailures;
        teamDetails.forces += forces;
        teamDetails.forceFailures += forceFailures;
        teamDetails.steals += steals;
        teamDetails.stealFailures += stealFailures;
        teamDetails.concededSteals += concededSteals;
        teamDetails.concededStealFailures += concededStealFailures;
    }

    const calculateEndStatsForYellow = (teamDetails: FullDetails, game: Game) => {
        var pointsInGame = game.scoreboard.reduce((a, b) => a + b.yellowScore, 0);
        var pointsAgainstInGame = game.scoreboard.reduce((a, b) => a + b.redScore, 0);
        var hammerConversions = game.scoreboard.filter(e => e.hammerTeamName ===team && e.yellowScore >= 2).length;
        var hammerFailures = game.scoreboard.filter(e => e.hammerTeamName === team && (e.yellowScore === 1 || e.redScore >= 1)).length;

        var forces = game.scoreboard.filter(e => e.hammerTeamName !==team && e.redScore === 1).length;
        var forceFailures = game.scoreboard.filter(e => e.hammerTeamName !== team && e.redScore > 1).length;

        var steals = game.scoreboard.filter(e => e.hammerTeamName !== team && e.yellowScore >= 1).length;
        var stealFailures = game.scoreboard.filter(e => e.hammerTeamName !== team && e.yellowScore === 0).length;

        var concededSteals = game.scoreboard.filter(e => e.hammerTeamName === team && e.redScore >= 1).length;
        var concededStealFailures = game.scoreboard.filter(e => e.hammerTeamName === team && e.redScore === 0).length;

        teamDetails.ptsFor.push(pointsInGame);
        teamDetails.ptsAgainst.push(pointsAgainstInGame);
        teamDetails.hammerSuccesses += hammerConversions;
        teamDetails.hammerFailures += hammerFailures;
        teamDetails.forces += forces;
        teamDetails.forceFailures += forceFailures;
        teamDetails.steals += steals;
        teamDetails.stealFailures += stealFailures;
        teamDetails.concededSteals += concededSteals;
        teamDetails.concededStealFailures += concededStealFailures;
    }

    const convertFullDetailsToSummarizedDetails = (fullDetails: FullDetails[]): SummarizedDetails[] => {
        return fullDetails.map(fd => {
            return {
                id: fd.id,
                name: fd.name,
                team: fd.team,
                wins: fd.wins,
                losses: fd.losses,
                ties: fd.ties,
                averagePts: displayableNumber(fd.ptsFor.reduce((a, b) => a + b, 0) / fd.ptsFor.length, false),
                averagePtsAgainst: displayableNumber(fd.ptsAgainst.reduce((a, b) => a + b, 0) / fd.ptsAgainst.length, false),
                hammerEfficiency: displayableNumber((100* fd.hammerSuccesses / (fd.hammerSuccesses + fd.hammerFailures)), true),
                forceEfficiency: displayableNumber(100* fd.forces / (fd.forces + fd.forceFailures), true),
                stealEfficiency: displayableNumber(100* fd.steals / (fd.steals + fd.stealFailures), true),
                stealDefenseEfficiency: displayableNumber(100* fd.concededSteals / (fd.concededSteals + fd.concededStealFailures), true),
                hammerMinusSteal: displayableNumber((fd.hammerSuccesses / (fd.hammerSuccesses + fd.hammerFailures)) - (fd.concededSteals / (fd.concededSteals + fd.concededStealFailures)), true)
            } as SummarizedDetails;
        });
    }

    const displayableNumber = (num: number, addPercentSign: boolean):string => {
        return Number.parseFloat(num.toFixed(2)).toString() + (addPercentSign ? '%' : '');
    }

    return (
        <div>
            <h3>Stats by Season</h3>
            <ReactDataGrid idProperty='id' columns={allStatisticsColumns} dataSource={getTeamStats()} style={gridStyle} />
        </div>
    )
};